import { ConsoleLogger } from "aws-amplify/utils";
import { AWSCloudWatchProvider } from "./AWSCloudWatchProvider";

import type { Client } from "src/graphql/gql-types";

interface LogData {
  activeClient: Partial<Client> & Pick<Client, "cli_email">;
  action: string;
  message: string;
  id: string;
}

// initiate logger
const logger = new ConsoleLogger("PaymentsPlatformWebApp", "INFO");

let env = process.env.NEXT_PUBLIC_APP_ACCOUNT;
if (!env) {
  env = "nonprod";
}

logger.addPluggable(
  new AWSCloudWatchProvider({
    logGroupName: `/ct/cloud/New-Payments-Platform-${env}`,
    logStreamName: "WebActivity",
    region: "eu-west-2",
  }),
);

export const logWebActivity = ({
  activeClient,
  action,
  message,
  id,
}: LogData): void => {
  if (activeClient.cli_reference) {
    const contact = `${activeClient.ctc_first_name} ${activeClient.ctc_last_name}`;
    const client = activeClient.cli_name ? `${activeClient.cli_name} ` : "";
    message = `${contact} ${client}/ ${activeClient.cli_reference} ${message}`;
  }

  // Wrapping the logger ensures that the order of the log keys is consistent
  // This is important for the CloudWatch Insights query
  logger.info(
    JSON.stringify({
      timestamp: new Date().toISOString(),
      login: activeClient.ctc_email,
      clientId: activeClient.cli_id || "",
      action,
      message,
      id,
    }),
  );
};

export const generateUUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
