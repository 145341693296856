import React from "react";
import classNames from "classnames";
import { OptionProps } from "src/core/select/Option";

type Ref = HTMLLIElement;

const Option = React.forwardRef<Ref, OptionProps>(
  ({ isHighlighted, children, ...props }, ref) => {
    const itemClasses = classNames(
      "cursor-pointer select-none relative py-2 px-3 flex space-x-2 items-center",
      isHighlighted ? "bg-teal-50" : "text-neutral-600",
    );

    return (
      <li {...props} ref={ref} className={itemClasses}>
        {children}
      </li>
    );
  },
);

Option.displayName = "Option";

export default Option;
